import React from 'react';
import SectionHeader from '../../common/SectionHeader';
import Step from './Step';

import { ReactComponent as BackgroundImage } from '../../assets/logo_infinity.svg';

function Process({steps, color}) {
  const cards = steps.map((step, index) => (
    <Step position={index + 1} last={index === steps.length - 1} color={color} title={step.title} description={step.description} />
  ));

  return (
      <section id="process" className="relative content-center overflow-x-clip py-[5rem] px-10">
        <BackgroundImage className="absolute text-primary-300 right-[-445px] w-[1266px] h-[654px] bottom-0 transform translate-y-[188px] opacity-15 z-10" />
        <div className="relative max-w-[1200px] mx-auto z-20">
          <SectionHeader title="Notre process" />
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 pt-[2.03125rem] gap-5 mx-auto justify-items-center justify-center">
            {cards}
          </div>
        </div>
      </section>
    );
}

export default Process;