import React from 'react';
import { Helmet } from 'react-helmet';

import Drawer from "./common/Drawer";
import PageTitle from "./common/PageTitle";

function NotFound({route}) {
  return [
    <Helmet>
      <title>Page introuvable | CDO Apps</title>
      <meta name="description" content="Désolé, la page que vous recherchez n'existe pas." />
      <meta property="og:title" content="Page introuvable | CDO Apps" />
      <meta property="og:description" content="Désolé, la page que vous recherchez n'existe pas." />
    </Helmet>,
    <PageTitle id="not-found" title="Page introuvable" text="Désolé, la page que vous recherchez n'existe pas." />,
    <Drawer route={route} />
  ];
}

export default NotFound;
