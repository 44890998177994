import AppStore from '../../assets/projects/AppStore.png';
import GooglePlay from '../../assets/projects/GooglePlay.png';
import Steam from '../../assets/projects/Steam.png';

function StoreButton({href, src, alt}) {
    return (
        <a href={href} target="_blank" className={href === "#" ? "pointer-events-none opacity-50" : "transition duration-300 hover:opacity-50"}>
            <img src={src} alt={alt} className="h-auto w-[200px] sm:w-auto sm:h-[60px]" />
        </a>
    );
}

function Stores({appStore, googlePlay, steam}) {
    return (
        <section id="stores" className="relative overflow-x-clip pt-[3.75rem] pb-[5rem] px-10">
            <div className="flex flex-col relative max-w-[1200px] mx-auto">
                <h2 className="mx-auto text-base font-bold text-center text-dark uppercase">Disponible sur&nbsp;:</h2>
                <br />
                <div className="flex flex-col space-y-[40px] sm:flex-row sm:space-y-0 sm:space-x-[40px] items-center justify-center w-full">
                    { appStore && <StoreButton href={appStore} src={AppStore} alt="App Store" /> }
                    { googlePlay && <StoreButton href={googlePlay} src={GooglePlay} alt="Google Play" /> }
                    { steam && <StoreButton href={steam} src={Steam} alt="Steam" /> }
                </div>
            </div>
        </section>
    );
}

export default Stores;