function PageTitle({id, title, text}) {
    return (
      <section id={id} className="relative bg-primary-600 overflow-x-clip py-[5rem] px-10">
        <div className="max-w-[1200px] mx-auto pt-[6.25rem] z-20">
          <h1 className="text-dark font-bold text-3xl uppercase">{title}</h1>
          {text && [
            <br />,
            <div className="text-dark font-bold text-base" dangerouslySetInnerHTML={{__html: text}}></div>
          ]}
        </div>
    </section>
    );
  }

export default PageTitle;