import React from 'react';
import SectionHeader from '../common/SectionHeader';

import Project from './projects/Project';
import MediaQuery from '../common/MediaQuery';

import ProjectQuery from '../model/Projects';

function Projects() {
  const corners = [
    "top-left bottom-left bottom-right",
    "top-left top-right bottom-left bottom-right",
    "top-left top-right bottom-right"
  ];
  
  const xsmallComponents = ProjectQuery({limit: 1}).map((project, index) => (
    <Project
      thumbnail={project.thumbnail}
      title={project.title}
      description={project.description}
      href={`/realisations/${project.slug}`}
      corners={corners[0]}
      icon={project.icon}
    />
  ));

  const mediumComponents = ProjectQuery({limit: 2}).map((project, index) => (
    <Project
      thumbnail={project.thumbnail}
      title={project.title}
      description={project.description}
      href={`/realisations/${project.slug}`}
      corners={corners[(index % 2) * 2]}
      icon={project.icon}
    />
  ));

  const components = ProjectQuery({limit: 3}).map((project, index) => (
    <Project
      thumbnail={project.thumbnail}
      title={project.title}
      description={project.description}
      href={`/realisations/${project.slug}`}
      corners={corners[index % 3]}
      icon={project.icon}
    />
  ));

  return (
    <section id="projets" className="relative min-h-[40rem] overflow-x-clip py-[5rem] px-10">
      <div className="absolute top-0 left-0 w-full h-[26.25rem] bg-primary-500 z-0"></div>
      <div className="relative max-w-[1200px] mx-auto z-20">
        <SectionHeader title="Nos dernières réalisations" subtitle="Projets" />
        <div className="flex justify-center w-full pt-[1.8125rem] pb-[5rem] space-x-[30px]">
          <MediaQuery
            xsmall={xsmallComponents}
            medium={mediumComponents}
            large={components}
          />
        </div>
        <div className="flex justify-center w-full pb-[1.25rem]">
          <a className="bg-accent text-white text-center font-bold rounded-full uppercase py-[1.25rem] px-[60px] transition duration-300 hover:opacity-50" href="/realisations">Voir toutes nos réalisations</a>
        </div>
      </div>
    </section>
  );
}

export default Projects;
