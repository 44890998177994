import React from 'react';

function Project({thumbnail, icon, title, description, href, corners}) {
  corners = corners ? corners.split(' ') : [];

  const topLeftCorner = corners.includes('top-left') ? 'rounded-tl-[40px]' : '';
  const topRightCorner = corners.includes('top-right') ? 'rounded-tr-[40px]' : '';
  const bottomLeftCorner = corners.includes('bottom-left') ? 'rounded-bl-[40px]' : '';
  const bottomRightCorner = corners.includes('bottom-right') ? 'rounded-br-[40px]' : '';

  return (
    <div className={`relative flex flex-col flex-1 min-h-[31.25rem] overflow-clip bg-primary-500/50 ${topLeftCorner} ${topRightCorner} ${bottomLeftCorner} ${bottomRightCorner}`}>
      <a className="transition duration-300 hover:opacity-50" href={href}>
        <img src={thumbnail} alt={title} className="w-full h-[14.0625rem] object-cover" />
      </a>
      <div className="content-center w-full p-10 pt-[5.75rem]">
        <h3 className="text-base font-bold text-dark uppercase">{title}</h3>
        <br />
        <div className="text-base text-dark line-clamp-3 text-ellipsis" dangerouslySetInnerHTML={{__html: description}}></div>
      </div>
      <div className="flex grow"></div>
      <a className="text-lg ml-auto mr-10 mb-10 font-bold text-accent underline transition duration-300 hover:opacity-50" href={href}>{">"} En savoir plus</a>
      <div className="absolute left-1/2 top-[14.0625rem] transform -translate-x-1/2 -translate-y-1/2 rotate-45 bg-white rounded-[30px] w-[110px] h-[110px] overflow-clip">
        <img src={icon} alt={title} className="absolute w-[155px] h-[155px] max-w-max object-cover left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 -rotate-45" />
      </div>
    </div>
  );
}

export default Project;
