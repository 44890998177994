function CallToAction({id, title, text, action, href}) {
    return (
      <section id={id} className="relative bg-primary-300 overflow-x-clip py-[5rem] px-10">
        <div className="flex flex-col justify-center max-w-[1200px] mx-auto z-20">
          <h1 className="text-white font-bold text-3xl text-center">{title}</h1>
          {text && [
            <br />,
            <div className="text-white font-bold text-center text-base" dangerouslySetInnerHTML={{__html: text}}></div>
          ]}
          <br />
          <div className="flex justify-center">
            <a className="bg-accent text-white text-center font-bold rounded-full uppercase py-[1.25rem] px-[60px] transition duration-300 hover:opacity-50" href={href}>{action}</a>
          </div>
        </div>
    </section>
    );
  }

export default CallToAction;