function Option({ href, content, selected, onClick }) {
  const font = selected ? "font-bold" : "font-medium";
  return (
    <li><a className={`text-white text-lg ${font} lowercase transition duration-300 hover:opacity-50`} href={href} onClick={onClick}>{content}</a></li>
  );
}

function Drawer({route}) {
  const state = route.drawer;
  const visible = state[0];
  const setVisible = state[1];

  const closeSelf = () => setVisible(false);
  const path = route.status < 400 ? window.location.pathname : "/not-found";

  return (
    <div
      className={`fixed right-0 top-0 px-10 py-[1.5625rem] h-screen bg-primary-300 drop-shadow-lg md:hidden z-50 transform transition-transform duration-300 ${
        visible ? 'translate-x-0' : 'translate-x-full'
      }`}
    >
      <ul className="flex flex-col space-y-5">
        <li className="ml-auto">
          <button className="text-white font-bold text-4xl transition duration-300 hover:opacity-50" onClick={closeSelf}>
            ✕
          </button>
        </li>
        <Option href="/#expertises" content="Expertises" selected={path.startsWith("/expertises")} onClick={closeSelf} />
        <Option href="/realisations" content="Réalisations" selected={path.startsWith("/realisations")} onClick={closeSelf} />
        <Option href="/equipe" content="L'équipe" selected={path.startsWith("/equipe")} onClick={closeSelf} />
        <Option href="/contact" content="Contact" selected={path.startsWith("/contact")} onClick={closeSelf} />
      </ul>
    </div>
  );
}

export default Drawer;