import React from 'react';
import SectionHeader from '../common/SectionHeader';
import Service from './services/Service';

import { ReactComponent as BackgroundImage } from '../assets/logo_infinity.svg';

import PackageImage from '../assets/home/package.jpeg';
import TPMImage from '../assets/home/tpm.jpeg';
import TailoredImage from '../assets/home/tailored.jpeg';
import MediaQuery from '../common/MediaQuery';

function Services() {
  const allServices = [
    <Service
      image={PackageImage}
      title="Forfait"
      description="Contrôlez votre budget dès le départ avec nos forfaits. Nous collaborons sur un cahier des charges précis pour garantir transparence et conformité. Des solutions prêtes-à-l’emploi, adaptées à vos besoins."
      color="accent"
      corners="top-left bottom-left bottom-right"
    />,
    <Service
      image={TPMImage}
      title="Régie"
      description="Contrôle total sur le processus de développement. Idéal pour les projets complexes, la régie offre une flexibilité maximale et une personnalisation complète."
      color="primary-300"
      corners="top-left top-right bottom-left bottom-right"
    />,
    <Service
      image={TailoredImage}
      title="Solution sur mesure"
      description="Solutions adaptées à vos besoins. Notre approche sur mesure permet une personnalisation complète, façonnant chaque aspect de votre application mobile selon vos exigences, pour une réponse parfaite à vos attentes."
      color="accent"
      corners="top-left top-right bottom-right"
    />
  ];
  
  return (
    <section id="services" className="relative bg-primary-500/40 content-center overflow-x-clip py-[5rem] px-10">
      <BackgroundImage className="absolute text-primary-300 right-[-445px] w-[1266px] h-[654px] top-1/2 transform -translate-y-1/2 opacity-15 z-10" />
      <div className="relative max-w-[1200px] mx-auto z-20">
        <SectionHeader title="Nos offres et services" subtitle="Savoir-faire" />
        <div className="hidden bg-accent"></div>
        <div className="hidden bg-primary-300"></div>
        <div className="flex flex-wrap justify-center py-[2.03125rem] mx-[-37.5px] w-[calc(100% + 75px)]">
          {allServices}
        </div>
        <div className="flex justify-center w-full">
          <a className="bg-accent text-white text-center font-bold rounded-full uppercase py-[1.25rem] px-[60px] transition duration-300 hover:opacity-50" href="/contact">Contactez-nous</a>
        </div>
      </div>
    </section>
  );
}

export default Services;
