import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Header from './common/Header';
import Footer from './common/Footer';
import Router from './Router';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
const header = ReactDOM.createRoot(document.getElementById('header'));
const footer = ReactDOM.createRoot(document.getElementById('footer'));

function onRouteChanged(route) {
  header.render(
    <React.StrictMode>
      <Header route={route} />
    </React.StrictMode>
  );

  footer.render(
    <React.StrictMode>
      <Footer route={route} />
    </React.StrictMode>
  );
};

root.render(
  <React.StrictMode>
    <Router path={window.location.pathname} onRouteChanged={onRouteChanged} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
