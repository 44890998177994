import React from 'react';
import MediaQuery from '../../common/MediaQuery';

import Project from './Project';

function Projects({items, color}) {
  const projects = items.map((project, index) => {
    const left = index % 2 === 0;
    return (
      <Project
        image={project.image}
        title={project.title}
        description={project.description}
        focus={project.focus}
        slug={project.slug}
        color={color}
        left={left}
      />);
  });
  const smallProjects = items.map((project) => (
    <Project
      image={project.image}
      title={project.title}
      description={project.description}
      focus={project.focus}
      slug={project.slug}
      color={color}
      left={true}
    />)
  );

  return (
    <section id="projects" className="relative bg-primary-500/40 content-center overflow-x-clip py-[5rem] px-10 z-20">
      <div className="relative flex flex-col space-y-12 max-w-[1200px] mx-auto">
      <MediaQuery
          medium={smallProjects}
          large={projects}
        />
      </div>
    </section>
  );
}

export default Projects;
