import React from 'react';

import { ReactComponent as LogoLight } from '../assets/logo_light.svg';

function TabAnchor({ href, content }) {
  return (
    <li><a className="text-white text-base transition duration-300 hover:opacity-50" href={href}>{content}</a></li>
  );
}

function SocialLink({ href, icon }) {
  return (
    <li><a className="text-accent text-4xl transition duration-300 hover:opacity-50" href={href} target="_blank">{icon}</a></li>
  );
}

function Footer({route}) {
  return (
    <div className="relative bg-primary-100 w-full px-10">
      <div className="relative max-w-[1200px] mx-auto relative z-20">
        <div className="flex w-full py-[3.125rem]">
          <div className="flex flex-col w-1/2 pr-4">
            <a className="w-full max-w-[261px] transition duration-300 hover:opacity-50" href="/"><LogoLight className="w-full h-auto text-white" /></a>
            <div className="text-white text-xs pt-[1.625rem]">Développeur d'apps mobiles</div>
          </div>
          <div className="flex w-1/2">
            <ul className="flex flex-col space-y-3">
              <TabAnchor href="/#expertises" content="Expertises" />
              <TabAnchor href="/realisations" content="Réalisations" />
              <TabAnchor href="/equipe" content="L'équipe" />
              <TabAnchor href="/contact" content="Contact" />
            </ul>
            <ul className="flex flex-col space-y-3 sm:flex-row sm:space-x-9 sm:space-y-0 ml-auto">
              <SocialLink href="https://www.linkedin.com/company/cdo-apps" icon={<i class="fa-brands fa-linkedin"></i>} />
              <SocialLink href="https://facebook.com/61558388555678" icon={<i class="fa-brands fa-facebook"></i>} />
              <SocialLink href="https://www.instagram.com/pockethamstermania_game" icon={<i class="fa-brands fa-square-instagram"></i>} />
            </ul>
          </div>
        </div>
        <div className="w-full bg-primary-300 h-[1px]"></div>
        <div className="w-full text-white text-base text-center py-[3.125rem]"><a className="transition duration-300 hover:opacity-50" href="/mentions-legales">Mentions légales</a> - Tous droits réservés © CDO Apps {new Date().getFullYear()}</div>
      </div>
    </div>
  );
}

export default Footer;
