function Member({name, position, text, techs, image, website, color, corners}) {
    color = "bg-" + color;
    return (
        <div className="relative flex flex-col flex-1 content-center max-w-[290px] px-5 py-10 text-base text-center text-dark ">
            <div className={`absolute top-[122.5px] left-0 right-0 bottom-0 bg-primary-700 z-0 ${corners}`}></div>
            <div className="flex flex-col flex-1 z-10">
                {image}
                <br />
                <h3 className={`flex text-lg font-bold text-white ${color} rounded-full px-6 py-2 mx-auto`}>{name}</h3>
                <br />
                <div className="text-lg font-bold min-h-[3.5rem]">{position}</div>
                <br />
                <div dangerouslySetInnerHTML={{__html: text}}></div>
                <br />
                <div className="flex justify-center mt-auto">
                    {techs.map((tech, index) => (
                        <div key={index} className={`flex items-center justify-center px-4 py-1 mx-1 text-xs text-white ${color} rounded-[5px]`}>{tech}</div>
                    ))}
                </div>
                {
                    website && ([
                        <br />,
                        <a className="text-dark text-2xl transition duration-300 hover:opacity-50" href={website} target="_blank"><i class="fa-solid fa-link"></i></a>
                    ])
                }
            </div>
        </div>
    )
}

export default Member;