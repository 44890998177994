import React from "react";

function Figure({amount, title}) {
  return (
    <div className="flex flex-col items-center space-y-2 mx-10 my-[2.5rem]">
      <div className="text-7xl text-dark font-bold">{amount}</div>
      <div className="text-xl text-dark font-bold lowercase text-center">{title}</div>
      <div className="w-[20px] aspect-square bg-primary-300 rounded-full"></div>
    </div>
);
}

export default Figure;