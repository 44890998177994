import React from 'react';
import { Helmet } from 'react-helmet';

import CallToAction from "./common/CallToAction";
import Drawer from "./common/Drawer";
import PageTitle from "./common/PageTitle";
import Skills from "./home/Skills";
import Members from "./team/Members";

function Team({route}) {
  return [
    <Helmet>
      <title>L'équipe | CDO Apps</title>
      <meta name="description" content="Découvrez l'équipe de CDO Apps, composée de designers, développeurs, graphistes... Nous travaillons ensemble pour vous proposer des applications mobiles au design unique et à la pointe de la technologie." />
      <meta property="og:title" content="L'équipe | CDO Apps" />
      <meta property="og:description" content="Découvrez l'équipe de CDO Apps, composée de designers, développeurs, graphistes... Nous travaillons ensemble pour vous proposer des applications mobiles au design unique et à la pointe de la technologie." />
    </Helmet>,
    <PageTitle id="equipe" title="L'équipe" text="Nous travaillons essentiellement pour ceux qui utilisent nos créations. Nous sommes designers, développeurs, graphistes... Voici la somme de nos talents." />,
    <Members />,
    <Skills />,
    <CallToAction id="contact" title="Votre projet nécessite une de nos compétences&nbsp;?" text="Travaillons ensemble, CDO Apps est prêt à relever le défi&nbsp;! Contactez-nous pour discuter de votre projet et obtenir un devis personnalisé&nbsp;: selon votre besoin, nous pourrons vous proposer une solution sur mesure incluant un plan de développement, un design personnalisé et un suivi de qualité." action="Contactez-nous" href="/contact" />,
    <Drawer route={route} />
  ];
}

export default Team;
