import React from 'react';
import { Helmet } from 'react-helmet';

import Drawer from "./common/Drawer";
import PageTitle from "./common/PageTitle";

function sypher(email) {
  return `<a class="text-base font-bold text-dark underline transition duration-300 hover:opacity-50" data-desypher="${btoa(email)}" href="#" data-href="${btoa("mailto:" + email)}">
      chiffré
    </a>`;
}

function Section({id, title, text, className}) {
    return (
        <section id={id} className={"relative overflow-x-clip px-10  pt-10" + (className ? " " + className : "")}>
          <div className="max-w-[1200px] mx-auto">
            <h2 className="text-base font-bold text-dark uppercase">{title}&nbsp;:</h2>
            <br />
            <div className="text-base text-dark" dangerouslySetInnerHTML={{__html: text}} />
          </div>
        </section>
    );
}

function LegalNotice({route}) {
  return [
    <Helmet>
      <title>Mentions légales | CDO Apps</title>
      <meta name="description" content="Découvrez les mentions légales du site de CDO Apps." />
      <meta property="og:title" content="Mentions légales | CDO Apps" />
      <meta property="og:description" content="Découvrez les mentions légales du site de CDO Apps." />
    </Helmet>,
    <PageTitle id="mentions-legales" title="Mentions légales" />,
    <Section id="editeur" title="Éditeur" text={"CDO Apps est une SARL au capital de 5 000 €, immatriculée au Registre du Commerce et des Sociétés de Melun sous le numéro 825 071 905 00012.<br />Son siège social est situé au 27 rue de Belfort, 77340 Pontault-Combault.<br />TVA intracommunautaire&nbsp;: FR 88 825 071 905.<br />Adresse email&nbsp;: " + sypher("contact@cdo-apps.com") + "."} />,
    <Section id="directeur" title="Directeur de la publication" text="M. Clément Choquereau" />,
    <Section id="hebergeur" title="Hébergeur" text="Le site est hébergé par la société OVH, SAS au capital de 50 000 000 €, immatriculée au RCS de Lille sous le numéro 424 761 419 00045 et dont le siège social est situé au 2 rue Kellermann, 59100 Roubaix." />,
    <Section id="contact" title="Contact" text={"Pour toute question ou réclamation concernant le site, vous pouvez contacter notre service client par email à l'adresse suivante&nbsp;: " + sypher("contact@cdo-apps.com") + "."} />,
    <Section id="propriete-intellectuelle" title="Propriété intellectuelle" text="Tous les éléments du site, qu'ils soient visuels ou sonores, y compris la technologie sous-jacente, sont protégés par le droit d'auteur, des marques ou des brevets. Ils sont la propriété exclusive de CDO Apps.<br />L'utilisateur qui dispose d'un site Internet à titre personnel et qui désire placer, pour un usage personnel, sur son site un lien simple renvoyant directement à la homepage du site de CDO Apps, doit obligatoirement en demander l'autorisation à CDO Apps. Il ne s'agira pas dans ce cas d'une convention implicite d'affiliation." />,
    <Section id="donnees-personnelles" title="Données personnelles" text={"D'une manière générale, vous n'êtes pas tenu de nous communiquer vos données personnelles lorsque vous visitez notre site Internet.<br />Cependant, ce principe comporte certaines exceptions. En effet, pour certains services proposés par notre site, vous pouvez être amenés à nous communiquer certaines données telles que&nbsp;: votre nom, votre prénom, votre adresse électronique, et votre numéro de téléphone. Tel est le cas lorsque vous remplissez le formulaire qui vous est proposé en ligne, dans la rubrique « contact ».<br />Dans tous les cas, vous pouvez refuser de fournir vos données personnelles. Dans ce cas, vous ne pourrez pas utiliser les services du site, notamment celui de solliciter des renseignements sur notre entreprise, ou de recevoir les lettres d'information.<br />Vous avez le droit à l'oubli, à la rectification et à la suppression de vos données personnelles. Pour exercer ce droit, vous pouvez nous contacter par email à l'adresse suivante&nbsp;: " + sypher("contact@cdo-apps.com") + "."} />,
    <Section id="liens-hypertextes" title="Liens hypertextes" text="Le site peut contenir des liens hypertextes vers d'autres sites. CDO Apps n'est pas responsable du contenu de ces sites." />,
    <Section id="loi-applicable" title="Loi applicable" text="Le site est soumis à la loi française." className="pb-10" />,
    <Drawer route={route} />
  ];
}

export default LegalNotice;
