function Feature({title, description}) {
    return [
        <h3 className="text-base font-bold text-dark">- {title}&nbsp;:</h3>,
        <div className="text-base text-dark" dangerouslySetInnerHTML={{__html: description}}></div>
    ];
}

function Features({title, features, lastSection}) {
    const rowsPerColumn = Math.ceil(features.length / 2);
    
    const firstFeatures = features.slice(0, rowsPerColumn);
    const secondFeatures = features.slice(rowsPerColumn);

    const column = (features) => (<div className="flex flex-col flex-1">
      {features.map((feature, index) => [
        index > 0 && <br />,
        <Feature title={feature.title} description={feature.description} />
      ])}
      </div>
    );

    return (
        <section id="fonctionnalites" className={"relative overflow-x-clip pt-[3.75rem] px-10" + (lastSection ? " pb-[5rem]" : "")}>
            <div className="flex flex-col relative max-w-[1200px] mx-auto">
                <h2 className="text-base font-bold text-dark uppercase">{title} inclut&nbsp;:</h2>
                <br />
                <div className="flex flex-col items-center gap-x-12 lg:items-start lg:flex-row">
                    { column(firstFeatures) }
                    { secondFeatures.length > 0 && [
                        <br className="lg:hidden" />,
                        column(secondFeatures)
                    ]}
                </div>
            </div>
        </section>
    );
}

export default Features;