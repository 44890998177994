function Step({position, last, color, title, description}) {
    const first = (position === 1);
    const corners = first ? "rounded-tl-[40px] rounded-bl-[40px] rounded-br-[40px]" : last ? "rounded-tl-[40px] rounded-tr-[40px] rounded-br-[40px]" : "rounded-[40px]";
    const background = position % 2 === 0 ? `bg-${color}` : "bg-primary-300";
    const justifyContent = position % 2 === 0 ? "sm:justify-self-start lg:justify-self-center" : "sm:justify-self-end lg:justify-self-center";
    return (
        <div className={`relative flex-1 items-center max-w-[285px] min-h-[270px] px-5 py-8 ${corners} ${background} ${justifyContent} text-white`}>
            {!first && (
                <div className="absolute left-0 top-1/2 transform -translate-y-[10px] w-0 h-0 border-t-[10px] border-t-transparent border-b-[10px] border-b-transparent border-l-[9px] border-l-white"></div>
            )}
            <div className="text-4xl font-bold text-center uppercase">{position}</div>
            <br />
            <h3 className="text-xl font-bold text-center uppercase">{title}</h3>
            <br />
            <div className="text-base/[1.375rem] text-center" dangerouslySetInnerHTML={{__html: description}}></div>
        </div>
    );
}

export default Step;