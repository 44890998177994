import React from 'react';
import Member from './include/Member';

import { ReactComponent as BackgroundImage } from '../assets/logo_infinity.svg';

import MemberQuery from '../model/Members';
import MediaQuery from '../common/MediaQuery';

function Members() {
  const cards = (members, cols) => members.map((member, index) => {
    const color = `team-${(1 + (index % 4))}`;
    var corners = "rounded-[40px]";
    
    if (cols > 1) {
      index = index % cols;
      if (index == 0) {
        corners += " rounded-br-none";
      } else if (index == cols - 1) {
        corners += " rounded-bl-none";
      }
    }

    return (
      <Member
        name={member.name}
        position={member.position}
        image={member.image}
        text={member.text}
        techs={member.techs}
        website={member.website}
        color={color}
        corners={corners}
        />
    );
  });

  const grid = (filter) => {
    const members = MemberQuery({filter: filter});
    const cols = members.length > 3 ? 4 : 3;
    const max = cols == 4 ? "grid-cols-4" : "grid-cols-3";
    return <MediaQuery
        sm-width="680px"
        md-width="1024px"
        lg-width="1280px"
        xsmall={
          <div className={`grid grid-cols-1 pt-[2.03125rem] gap-5 max-w-max mx-auto justify-items-center justify-center`}>
            {cards(members, 1)}
          </div>
        }
        small={
          <div className={`grid grid-cols-2 pt-[2.03125rem] gap-5 max-w-max mx-auto justify-items-center justify-center`}>
            {cards(members, 2)}
          </div>
        }
        medium={
          <div className={`grid grid-cols-3 pt-[2.03125rem] gap-5 max-w-max mx-auto justify-items-center justify-center`}>
            {cards(members, 3)}
          </div>
        }
        large={
          <div className={`grid ${max} pt-[2.03125rem] gap-5 max-w-max mx-auto justify-items-center justify-center`}>
            {cards(members, cols)}
          </div>
        }
      />;
  };

  return (
      <section id="membres" className="relative content-center overflow-x-clip py-[5rem] px-10">
        <div className="bg-team-1 bg-team-2 bg-team-3 bg-team-4 hidden"></div>
        <BackgroundImage className="absolute text-accent left-[-445px] w-[609px] h-[314px] bottom-0 transform translate-y-1/2 z-10" />
        <div className="relative max-w-[1200px] mx-auto z-20">
          <h2 className="text-base font-bold text-center text-dark uppercase">Notre équipe interne&nbsp;:</h2>
          <br />
          <div className="text-base text-center text-dark">
            Chez CDO Apps, nous accompagnons nos clients de l’idée initiale jusqu’au produit final. Notre force réside dans une équipe soudée, capable de gérer l’ensemble du cycle de vie d’une application mobile, en assurant à la fois la qualité technique et le suivi de chaque projet.
          </div>
          {grid('internal')}
          <h2 className="text-base font-bold text-center text-dark uppercase mt-[4.375rem]">Nos experts de confiance&nbsp;:</h2>
          <br />
          <div className="text-base text-center text-dark">
            Pour enrichir nos réalisations d’une dimension graphique, visuelle et sonore, nous collaborons avec des prestataires de confiance. Cette complémentarité garantit des applications à la fois performantes, intuitives et esthétiques, parfaitement adaptées à vos besoins.
          </div>
          {grid('external')}
        </div>
      </section>
    );
}

export default Members;
