import React from 'react';

function Service({image, title, description, color, corners}) {
  color = color ? "bg-" + color : "bg-white";
  corners = corners ? corners.split(' ') : [];

  const topLeftCorner = corners.includes('top-left') ? 'rounded-tl-[40px]' : '';
  const topRightCorner = corners.includes('top-right') ? 'rounded-tr-[40px]' : '';
  const bottomLeftCorner = corners.includes('bottom-left') ? 'rounded-bl-[40px]' : '';
  const bottomRightCorner = corners.includes('bottom-right') ? 'rounded-br-[40px]' : '';

  return (
    <div className="flex flex-1 min-w-[380px] max-w-[420px] p-[37.5px]">
      <div className={`flex-1 min-h-[31.25rem] overflow-clip ${color} ${topLeftCorner} ${topRightCorner} ${bottomLeftCorner} ${bottomRightCorner}`}>
        <img src={image} alt={title} className="w-full h-[14.0625rem] object-cover" />
        <div className="content-center w-full p-10">
          <h3 className="text-xl font-bold text-white text-center uppercase">{title}</h3>
          <br />
          <div className="text-base text-white text-center" dangerouslySetInnerHTML={{__html: description}}></div>
        </div>
      </div>
    </div>
  );
}

export default Service;
