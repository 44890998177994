import React, { useState } from 'react';

import Project from '../home/projects/Project';
import MediaQuery from '../common/MediaQuery';

import ProjectQuery from '../model/Projects';
import Tags from './include/Tags';

function Projects() {
  const corners = [
    "top-left bottom-left bottom-right",
    "top-left top-right bottom-left bottom-right",
    "top-left top-right bottom-right"
  ];


  const tags = useState(null);
  const allProjects = ProjectQuery({filter: tags[0]});

  const card = (project, corners) => (
    <Project
      thumbnail={project.thumbnail}
      title={project.title}
      description={project.description}
      href={`/realisations/${project.slug}`}
      corners={corners}
      icon={project.icon}
    />
  );
    
  
  const xsmallComponents = allProjects.map((project, index) => card(project, corners[0]));
  const mediumComponents = allProjects.map((project, index) => card(project, corners[(index % 2) * 2]));
  const components = allProjects.map((project, index) => card(project, corners[index % 3]));

  return (
    <section id="projets" className="relative overflow-x-clip py-[5rem] px-10">
      <div className="relative max-w-[1200px] mx-auto z-20">
        <Tags state={tags} />
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-[30px] gap-y-[30px] justify-center w-full pt-[3.75rem]">
          <MediaQuery
            xsmall={xsmallComponents}
            medium={mediumComponents}
            large={components}
          />
        </div>
      </div>
    </section>
  );
}

export default Projects;
