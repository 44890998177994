import React from 'react';
import { Helmet } from 'react-helmet';

import CallToAction from "./common/CallToAction";
import Drawer from "./common/Drawer";
import PageTitle from "./common/PageTitle";

import Content from "./projects/Content";

function Projects({route}) {
  return [
    <Helmet>
      <title>Nos réalisations | CDO Apps</title>
      <meta name="description" content="CDO Apps est reconnu pour la qualité de ses applications. Découvrez quelques-unes de nos réalisations pour nos clients et pour notre propre compte." />
      <meta property="og:title" content="Nos réalisations | CDO Apps" />
      <meta property="og:description" content="CDO Apps est reconnu pour la qualité de ses applications. Découvrez quelques-unes de nos réalisations pour nos clients et pour notre propre compte." />
    </Helmet>,
    <PageTitle id="realisations" title="Nos réalisations" text="CDO Apps est reconu pour la qualité de ses applications. Nous avons travaillé pour de nombreux clients et également développé des applications pour notre propre compte. Retrouvez ici quelques-unes de nos réalisations." />,
    <Content />,
    <CallToAction id="contact" title="Vous avez une idée d'application en tête&nbsp;?" text="Nous sommes là pour vous aider à la concrétiser. CDO Apps est spécialisé dans le développement d'applications mobiles. Nous vous accompagnons de la conception à la publication sur les stores. Contactez-nous pour discuter de votre projet et obtenir un devis personnalisé." action="Contactez-nous" href="/contact" />,
    <Drawer route={route} />
  ];
}

export default Projects;
