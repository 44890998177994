import React from 'react';
import { Helmet } from 'react-helmet';

import Drawer from './common/Drawer';
import Figures from './home/Figures';
import HeroHeader from './home/HeroHeader';
import Projects from './home/Projects';
import Services from './home/Services';
import Skills from './home/Skills';
import Team from './home/Team';
import Testimony from './home/Testimony';

function Home({route}) {
  return [
    <Helmet>
      <title>Votre application mobile sur mesure | CDO Apps</title>
      <meta name="description" content="CDO Apps est un studio de développement d'applications mobiles sur mesure. Nous vous accompagnons de la conception à la publication sur les stores." />
      <meta property="og:title" content="Votre application mobile sur mesure | CDO Apps" />
      <meta property="og:description" content="CDO Apps est un studio de développement d'applications mobiles sur mesure. Nous vous accompagnons de la conception à la publication sur les stores." />
    </Helmet>,
    <HeroHeader/>,
    <Services/>,
    <Testimony/>,
    <Skills decoration={true}/>,
    <Team/>,
    <Figures/>,
    <Projects/>,
    <Drawer route={route}/>
  ];
}

export default Home;

