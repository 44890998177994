import React, {use, useEffect, useRef, useState} from 'react';

import Left from '../../assets/projects/left.svg';
import Right from '../../assets/projects/right.svg';

function Gallery({title, screenshots, lastSection}) {
  const containerRef = useRef(null);
  const overlayRef = useRef(null);
  
  const [insets, setInsets] = useState({left: 0, right: 0});
  const [imagesLoaded, setImagesLoaded] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [page, setPage] = useState(0);

  const updatePage = () => {
    if (containerRef.current) {
      const center = containerRef.current.scrollLeft + containerRef.current.offsetWidth / 2;
      const children = containerRef.current.children;

      for (let index = 0; index < children.length; index++) {
        const child = children[index];
        
        const left = child.offsetLeft;
        const right = left + child.offsetWidth;

        if (right > center) {
          console.log(index);
          setPage(index);
          return;
        }
      }
    }
  };

  const scrollToPage = (index) => {
    if (containerRef.current) {
      const child = containerRef.current.children[index];

      const containerWidth = containerRef.current.offsetWidth;
      const childWidth = child.offsetWidth;
      const inset = (containerWidth - childWidth) / 2;

      containerRef.current.scrollTo({
        left: child.offsetLeft - inset,
        behavior: "smooth"
      });
    }
  };

  useEffect(updatePage, [insets]);
  
  useEffect(() => {
    if (containerRef.current && imagesLoaded === screenshots.length) {
      const containerWidth = containerRef.current.offsetWidth;
      
      const children = containerRef.current.children;
      const firstChildWidth = children[0].offsetWidth;
      const lastChildWidth = children[children.length - 1].offsetWidth;
      
      const leftInset = Math.max((containerWidth - firstChildWidth) / 2, 30);
      const rightInset = Math.max((containerWidth - lastChildWidth) / 2, 30);
      
      setInsets({left: leftInset, right: rightInset});
    }
  }, [imagesLoaded, windowWidth]);
  
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    containerRef.current.addEventListener('scroll', updatePage);
    return () => containerRef.current.removeEventListener('scroll', updatePage);
  }, []);

  const images = screenshots.map((screenshot, index) => {
    const first = index === 0;
    const last = index === screenshots.length - 1;
    return (
      <img
        src={screenshot}
        alt={`${title} - ${index + 1}`}
        className="object-cover h-[440px] max-h-[440px] w-auto max-w-none sm:w-full sm:h-auto sm:max-w-[50%] rounded-[20px] my-auto"
        onLoad={() => setImagesLoaded((prev) => prev + 1)}
        style={{
          marginLeft: first ? insets.left : 15,
          marginRight: last ? insets.right : 15
        }}
      />
    );
  });

  const overlayRight = Math.max(70, 30 + (windowWidth - Math.min(windowWidth, 1200)) / 2);
  
  return (
    <section id="details" className="relative overflow-x-clip px-10">
      <div ref={containerRef} className={"relative flex w-full max-w-[1200px] h-[37.5rem] bg-primary-600 mt-[3.75rem] overflow-x-scroll mx-auto" + (lastSection ? " mb-[5rem]" : "")}>
          {images}
      </div>
      <div ref={overlayRef} className="absolute bottom-[30px] right-[30px] flex space-x-[30px]" style={{right: overlayRight}}>
        <button className="transition duration-300 hover:opacity-50" onClick={() => scrollToPage(Math.max(0, page - 1))}>
          <img src={Left} alt="Précédent" className="w-[40px] h-auto" />
        </button>
        <button className="transition duration-300 hover:opacity-50" onClick={() => scrollToPage(Math.min(screenshots.length - 1, page + 1))}>
          <img src={Right} alt="Suivant" className="w-[40px] h-auto" />
        </button>
      </div>
    </section>
  );
}

export default Gallery;