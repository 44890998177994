function Tags({state}) {
    const selected = state[0];
    const setSelected = state[1];

    const allTags = [
        {name: "Tout"},
        {name: "iOS", slug: "ios"},
        {name: "Android", slug: "android"},
        {name: "Flutter", slug: "flutter"},
        {name: "Unity", slug: "unity"}
    ];

    const components = allTags.map((tag, index) => (
        <button
            className={`px-[35px] py-[25px] text-base font-bold rounded-full ${selected == tag.slug ? "bg-accent text-white" : "bg-primary-600 text-dark"} uppercase transition duration-300 hover:opacity-50`}
            onClick={() => setSelected(tag.slug)}
        >
            {tag.name}
        </button>
    ));

    return (
        <div className="flex space-x-5 overflow-x-scroll max-w-max mx-auto">
            {components}
        </div>
    );
}

export default Tags;