import React, { useEffect, useRef, useState } from 'react';
import MediaQuery from '../common/MediaQuery';
import SectionHeader from '../common/SectionHeader';
import Quote from './testimony/Quote';
import PageControl from './testimony/PageControl';

import { ReactComponent as BackgroundImage } from '../assets/logo_infinity.svg';

import TayasuiImage from '../assets/home/tayasui.jpeg';
import JBKImage from '../assets/home/jbk.jpeg';
import JetItNowImage from '../assets/home/jetitnow.jpeg';

function Testimony() {
  var animating = false;
  const [page, setPage] = useState(0);
  const scrollRef = useRef(null);

  useEffect(() => {
    const container = scrollRef.current;
    if (container) {
      const handleScroll = () => {
        if (animating) {
          return;
        }
        
        const newPage = Math.floor(container.scrollLeft / container.clientWidth + 0.5);
        if (newPage !== page) {
          setPage(newPage);
        }
      };

      container.addEventListener('scroll', handleScroll);

      return () => {
        container.removeEventListener('scroll', handleScroll);
      };
    }
  }, [page]);

  useEffect(() => {
    const container = scrollRef.current;
    if (container) {
      animating = true;
      container.scrollTo({
        left: container.clientWidth * page,
        behavior: "smooth"
      });
      setTimeout(() => {
        animating = false;
      }, 1000);
    }
  }, [page]);

  const allQuotes = [
    <Quote
      image={TayasuiImage}
      title="Tayasui"
      description="Apps pour artistes" 
      message="Nous avons collaboré avec plusieurs studios depuis 15 ans et CDO Apps a été de loin notre meilleure expérience. Ensemble, nous avons développé des applications de premier plan pour iOS et Mac, toujours avec un grand souci du détail et une efficacité irréprochable. C'est une équipe talentueuse et ultra consciencieuse que nous recommandons absolument."
      url="https://www.tayasui.com/"
      />,
    <Quote
      image={JBKImage}
      title="JBK Corporation"
      description="Agence de communication"
      message="Organisation, écoute et minutie ont été au coeur de notre collaboration avec CDO Apps. Tout au long de la mission c’est bien leur expertise technique autant que leur savoir-être qui nous ont permis d’avancer efficacement. Merci à toute l’équipe et à Clément."
      url="https://www.jbk-communication.fr/"
      />,
    <Quote
      image={JetItNowImage}
      title="JetItNow"
      description="Location de jet privé"
      message="CDO Apps a su répondre à nos attentes en nous proposant une solution sur mesure pour notre application mobile. Leur expertise technique et leur créativité ont été des atouts majeurs pour la réussite de ce projet."
      url="https://www.jetitnow.com/"
      />
  ];

  const smallLength = allQuotes.length;
  const length = Math.round(allQuotes.length / 2);

  return (
    <section id="references" className="relative overflow-x-clip py-[5rem] px-10">
      <BackgroundImage className="absolute text-primary-300 left-[-445px] w-[609px] h-[314px] bottom-0 transform translate-y-1/2 z-10" />
      <div className="relative max-w-[1200px] mx-auto z-20">
        <SectionHeader title="Ils nous ont fait confiance" subtitle="Références" />
        <div ref={scrollRef} className="flex flex-nowrap w-full py-[4.375rem] overflow-x-scroll">
          {allQuotes}
        </div>
        <MediaQuery 
          medium={<PageControl
            selected={page}
            setSelected={setPage}
            length={smallLength}/>}
          large={<PageControl
            selected={page}
            setSelected={setPage}
            length={length}/>}
        />
      </div>
    </section>
  );
}

export default Testimony;
