function HeroHeader({title, description, image, techs, website}) {
    return (
        <section id="titre" className="relative overflow-x-clip py-[5rem] px-10">
          <div className="relative max-w-[1200px] mx-auto">
            <div className="flex flex-col items-center gap-x-12 lg:items-start lg:flex-row">
              <div className="flex flex-col flex-1">
                <h2 className={"text-4xl font-bold text-dark uppercase"}>{title}</h2>
                <br />
                <h3 className="text-base font-bold text-dark uppercase">Description&nbsp;:</h3>
                <br />
                <div className="text-base text-dark" dangerouslySetInnerHTML={{__html: description}}></div>
                <br />
                <h3 className="text-base font-bold text-dark uppercase">Technologies utilisées&nbsp;:</h3>
                <br />
                <ul className="text-base text-dark">
                  {techs.map((tech) => (
                    <li>- {tech}</li>
                  ))}
                </ul>
                <br />
                { website && (
                <div className="flex justify-center lg:justify-start w-full">
                  <a className="bg-accent text-white text-center font-bold rounded-full uppercase py-[1.25rem] px-[60px] transition duration-300 hover:opacity-50" href={website} target="_blank">Voir le site de présentation</a>
                </div>
                )}
              </div>
              <br className="lg:hidden" />
              <div className="relative w-full max-w-[600px] lg:w-1/2 lg:my-auto">
                <img src={image} alt={title} className="object-cover w-full h-auto rounded-tl-[40px] rounded-tr-[40px] rounded-br-[40px]" />
              </div>
            </div>
          </div>
        </section>
    );
}

export default HeroHeader;