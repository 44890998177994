import React from 'react';

function OtherSkill({image, title, description, href, corners}) {
  corners = corners ? corners.split(' ') : [];

  const topLeftCorner = corners.includes('top-left') ? 'rounded-tl-[40px]' : '';
  const topRightCorner = corners.includes('top-right') ? 'rounded-tr-[40px]' : '';
  const bottomLeftCorner = corners.includes('bottom-left') ? 'rounded-bl-[40px]' : '';
  const bottomRightCorner = corners.includes('bottom-right') ? 'rounded-br-[40px]' : '';

  return (
    <div className="flex flex-1 min-w-[380px] max-w-[420px] p-[37.5px]">
      <div className={`flex flex-col flex-1 min-h-[31.25rem] overflow-clip bg-white p-10 ${topLeftCorner} ${topRightCorner} ${bottomLeftCorner} ${bottomRightCorner}`}>
        {image}
        <div className="content-center w-full pt-8">
          <h3 className="text-xl font-bold text-dark text-center uppercase">{title}</h3>
          <br />
          <div className="text-base text-dark text-center" dangerouslySetInnerHTML={{__html: description}}></div>
        </div>
        <div className="flex mx-auto mt-auto pt-8">
          <a className="text-lg font-bold text-accent underline transition duration-300 hover:opacity-50" href={href}>{">"} En savoir plus</a>
        </div>
      </div>
    </div>
  );
}

export default OtherSkill;
