import React from 'react';
import SectionHeader from '../../common/SectionHeader';
import OtherSkill from './OtherSkill';

import { ReactComponent as BackgroundImage } from '../../assets/logo_infinity.svg';


import { ReactComponent as MobileAppsImage } from '../../assets/skills/mobile_apps_sm.svg';
import { ReactComponent as BackendImage } from '../../assets/skills/backend_sm.svg';
import { ReactComponent as GraphicsImage } from '../../assets/skills/graphics_sm.svg';
import { ReactComponent as ProjectManagementImage } from '../../assets/skills/project_management_sm.svg';

function OtherSkills({mainSkill}) {
  const allSkills = [
    {
      id: "mobile-apps",
      image: <MobileAppsImage className="mx-auto" />,
      title: "Application mobile",
      description: "CDO Apps excelle dans le développement d’applications mobiles de qualité exceptionnelle. Notre équipe créative et expérimentée maîtrise les dernières technologies pour concevoir des applications intuitives, esthétiques et parfaitement adaptées à diverses plateformes, offrant une expérience utilisateur fluide et engageante.",
      href: "/expertises/apps-mobiles",
    },
    {
      id: "back-end",
      image: <BackendImage className="mx-auto" />,
      title: "Back end",
      description: "La solidité d’une application réside dans son infrastructure. Avec notre expertise Back End, nous assurons une base solide pour vos applications. De la gestion efficace des bases de données à la sécurité avancée, nous optimisons les performances pour garantir une expérience utilisateur fiable et sécurisée.",
      href: "/expertises/back-end",
    },
    {
      id: "graphics",
      image: <GraphicsImage className="mx-auto" />,
      title: "Développement 3D",
      description: "Explorez de nouvelles dimensions avec l’expertise polyvalente de CDO Apps en développement 3D, moteur graphique et jeu vidéo. Nous repoussons les limites de l’innovation en intégrant des technologies de pointe pour créer des simulations interactives et des expériences de jeu divertissantes.",
      href: "/expertises/developpement-3d",
    },
    {
      id: "project-management",
      image: <ProjectManagementImage className="mx-auto" />,
      title: "Gestion de projet",
      description: "La réussite de chaque projet repose sur une gestion efficace. Notre expertise en gestion de projet assure une coordination transparente, du concept à la livraison. Nous maintenons une communication constante pour garantir la réalisation réussie de vos projets.",
      href: "/expertises/gestion-de-projet",
    }
  ];

  const allOtherSkills = allSkills.filter(skill => skill.id !== mainSkill).map((skill, index) => {
    const first = index === 0;
    const last = index === allSkills.length - 2;

    return (
      <OtherSkill
        image={skill.image}
        title={skill.title}
        description={skill.description}
        href={skill.href}
        corners={`${first ? "top-left bottom-left bottom-right" : (last ? "top-left top-right bottom-right": "top-left top-right bottom-left bottom-right")}`}
      />);
  });
  
  return (
    <section id="autres-expertises" className="relative bg-primary-500/40 content-center overflow-x-clip py-[5rem] px-10">
      <BackgroundImage className="absolute text-white right-[-445px] w-[609px] h-[314px] bottom-0 transform translate-y-1/2 z-20" />
      <div className="relative max-w-[1200px] mx-auto z-20">
        <SectionHeader title="Nos autres domaines d'expertise" subtitle="Expertises" />
        <div className="hidden bg-accent"></div>
        <div className="hidden bg-primary-300"></div>
        <div className="flex flex-wrap justify-center py-[2.03125rem] mx-[-37.5px] w-[calc(100% + 75px)]">
          {allOtherSkills}
        </div>
        <div className="flex justify-center w-full font-bold text-dark pb-[1.625rem]">Besoin d'une expertise pour votre projet ?</div>
        <div className="flex justify-center w-full">
          <a className="bg-accent text-white text-center font-bold rounded-full uppercase py-[1.25rem] px-[60px] transition duration-300 hover:opacity-50" href="/contact">Contactez-nous</a>
        </div>
      </div>
    </section>
  );
}

export default OtherSkills;
