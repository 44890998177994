import React from 'react';
import SectionHeader from '../../common/SectionHeader';

import { ReactComponent as BackgroundImage } from '../../assets/logo_infinity.svg';

function Stack({techs}) {
  const cards = techs.map((tech, index) => (
    <div className="items-center p-8 m-6 rounded-[15px] bg-primary-600">
        <img src={tech.logo} alt={tech.name} className="w-auto h-[2.5rem] object-cover" />
    </div>
  ));

  return (
      <section id="stack" className="relative content-center overflow-x-clip py-[5rem] px-10">
        <BackgroundImage className="absolute text-primary-300 left-[-445px] w-[609px] h-[314px] top-0 transform -translate-y-1/2 z-10" />
        <div className="relative max-w-[1200px] mx-auto z-20">
          <SectionHeader title="Stack technique" />
          <div className="flex flex-wrap justify-center w-full pt-[2.03125rem] -m-6">
            {cards}
          </div>
        </div>
      </section>
    );
}

export default Stack;