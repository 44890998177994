import React from 'react';

function SectionHeader({title, subtitle, style}) {
  const subtitleColor = style == "dark" ? 'text-white' : 'text-accent';
  const titleColor = style == "dark" ? 'text-white' : 'text-dark';
  const embraceColor = style == "dark" ? 'text-white' : 'text-accent';

  return (
    <div className="w-full">
      {subtitle && <div className={subtitleColor + " font-bold text-base/[3.125rem] text-left uppercase"}>{subtitle}</div>}
      <h2 className={titleColor + " font-bold text-3xl text-left"}><span className={embraceColor}>{"{"}</span>&nbsp;{title}&nbsp;<span className={embraceColor}>{"}"}</span></h2>
    </div>
  );
}

export default SectionHeader;
