import React from 'react';

import { ReactComponent as QuoteEmbrace } from '../../assets/home/embrace.svg';

function Quote({image, title, description, message, url}) {
  return (
    <div className="flex flex-row min-w-[100%] lg:min-w-[50%] w-full lg:w-1/2 px-4">
      <div className="justify-center max-w-[125px] sm:max-w-[250px]">
        <a className="flex justify-center items-center bg-light rounded-full w-[125px] h-[125px] sm:w-[250px] sm:h-[250px] overflow-clip transition duration-300 hover:opacity-50" href={url} target="_blank">
          <div className="relative w-[100px] h-[100px] rounded-[25px] sm:w-[200px] sm:h-[200px] sm:rounded-[50px] overflow-clip transform rotate-45">
            <img src={image} alt={title} className="absolute w-[142px] h-[142px] sm:w-[283px] sm:h-[283px] max-w-max object-cover left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 -rotate-45" />
          </div>
        </a>
        <h3 className="text-base/[1.375rem] font-bold text-dark text-center mt-4">{title}</h3>
        <div className="text-xs/[1.375rem] font-light text-dark text-center" dangerouslySetInnerHTML={{__html: description}}></div>
      </div>
      <QuoteEmbrace className="min-w-[78px] mr-[-15px]" />
      <div className="text-base/[1.375rem] text-dark pt-10">«&nbsp;{message}&nbsp;»</div>
    </div>
  );
}

export default Quote;
