import React, { useRef, useEffect } from 'react';
import SectionHeader from '../common/SectionHeader';

import { ReactComponent as BackgroundImage } from '../assets/logo_infinity.svg';
import Skill from './skills/Skill';

import { ReactComponent as MobileAppsImage } from '../assets/skills/mobile_apps_sm.svg';
import { ReactComponent as BackendImage } from '../assets/skills/backend_sm.svg';
import { ReactComponent as GraphicsImage } from '../assets/skills/graphics_sm.svg';
import { ReactComponent as ProjectManagementImage } from '../assets/skills/project_management_sm.svg';

function Skills({decoration}) {
  const sectionRef = useRef(null);

  useEffect(() => {
    if (window.location.hash === '#expertises' && sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: 'instant' });
    }
  }, []);

  const allSkills = [
    <Skill
      image={<MobileAppsImage className="mx-auto" />}
      title="Application mobile"
      description="CDO Apps excelle dans le développement d’applications mobiles de qualité exceptionnelle. Notre équipe créative et expérimentée maîtrise les dernières technologies pour concevoir des applications intuitives, esthétiques et parfaitement adaptées à diverses plateformes, offrant une expérience utilisateur fluide et engageante."
      href="/expertises/apps-mobiles"
      corners="top-left bottom-left bottom-right"
    />,
    <Skill
      image={<BackendImage className="mx-auto" />}
      title="Back end"
      description="La solidité d’une application réside dans son infrastructure. Avec notre expertise Back End, nous assurons une base solide pour vos applications. De la gestion efficace des bases de données à la sécurité avancée, nous optimisons les performances pour garantir une expérience utilisateur fiable et sécurisée."
      href="/expertises/back-end"
      corners="top-left top-right bottom-right"
    />,
    <Skill
      image={<GraphicsImage className="mx-auto" />}
      title="Développement 3D"
      description={"Explorez de nouvelles dimensions avec l’expertise polyvalente de CDO Apps en développement 3D, moteur graphique et jeu vidéo.<br />Nous repoussons les limites de l’innovation en intégrant des technologies de pointe pour créer des simulations interactives et des expériences de jeu divertissantes."}
      href="/expertises/developpement-3d"
      corners="top-left bottom-left bottom-right"
    />,
    <Skill
      image={<ProjectManagementImage className="mx-auto" />}
      title="Gestion de projet"
      description={"La réussite de chaque projet repose sur une gestion efficace.<br />Notre expertise en gestion de projet assure une coordination transparente, du concept à la livraison. Nous maintenons une communication constante pour garantir la réalisation réussie de vos projets."}
      href="/expertises/gestion-de-projet"
      corners="top-left top-right bottom-right"
    />,
  ];

  return (
    <section id="expertises" ref={sectionRef} className="relative bg-primary-500/40 overflow-x-clip py-[5rem] px-10 z-20">
      <div className="max-w-[1200px] mx-auto">
        {decoration && <BackgroundImage className="absolute text-white right-[-445px] w-[609px] h-[314px] bottom-0 transform translate-y-1/2" />}
        <SectionHeader title="Nos compétences à votre service" subtitle="Expertises" />
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-[60px] gap-y-[3.125rem] py-[4.375rem]">
          {allSkills}
        </div>
        <div className="flex justify-center w-full font-bold text-dark pb-[1.625rem]">Un projet&nbsp;? Discutons-en&nbsp;!</div>
        <div className="flex justify-center w-full pb-[1.25rem]">
          <a className="bg-accent text-white font-bold rounded-full uppercase py-[1.25rem] px-[60px] z-30 transition duration-300 hover:opacity-50" href="/contact">Contactez-nous</a>
        </div>
      </div>
    </section>
  );
}

export default Skills;