import React from 'react';
import { Helmet } from 'react-helmet';

import CallToAction from "../common/CallToAction";
import Drawer from "../common/Drawer";
import Projects from "../home/Projects";
import PageTitle from "./include/PageTitle";

import { ReactComponent as GraphicsIcon } from "../assets/skills/graphics.svg";
import OtherSkills from "./include/OtherSkills";
import SkillProjects from "./include/Projects";
import Process from "./include/Process";
import Stack from "./include/Stack";

import ProjectQuery from "../model/Projects";

import Unity from "../assets/skills/Unity.png";
import OpenGL from "../assets/skills/OpenGL.png";
import C from "../assets/skills/C.png";
import Cpp from "../assets/skills/C++.png";
import Blender from "../assets/skills/Blender.png";

function Graphics({route}) {
  const steps = [
    {
      title: "Export 3D",
      description: "Vos éléments 3D doivent être exportés dans un format compatible avec les moteurs graphiques. Nous maîtrisons les formats de fichiers les plus courants et réalisons des outils de conversion personnalisés pour garantir une intégration parfaite."
    },
    {
      title: "Moteur graphique",
      description: "Le moteur graphique est le cœur de vos projets 3D. Nous maintenons une connaissance à jour des moteurs graphiques que nous maîtrisons pour garantir des rendus de qualité et des performances optimales.<br /><br />Nous réalisons également des moteurs dédiés pour des projets spécifiques."
    },
    {
      title: "Gameplay",
      description: "Lorsque votre projet nécessite une dimension interactive, le gameplay est un élément clé. Nous concevons des mécaniques de jeu innovantes et engageantes pour offrir une expérience utilisateur unique et divertissante."
    },
    {
      title: "Optimisation",
      description: "L’optimisation est essentielle pour garantir le meilleur compromis entre un rendu le plus fidèle à vos attentes et des performances de premier plan. Nous optimisons modèles 3D, textures et shaders pour garantir une expérience utilisateur la plus fluide possible."
    }
  ];
  const projects = ProjectQuery({filter: "graphics"});
  const techs = [
    { name: "Unity", logo: Unity },
    { name: "OpenGL", logo: OpenGL },
    { name: "C", logo: C },
    { name: "C++", logo: Cpp },
    { name: "Blender", logo: Blender }
  ];

  return [
    <Helmet>
      <title>Notre expertise en développement 3D | CDO Apps</title>
      <meta name="description" content="Découvrez notre expertise en développement 3D. Nous repoussons les limites de l'innovation en intégrant des technologies de pointe pour créer des simulations interactives et des expériences de jeu divertissantes. Contactez-nous pour discuter de votre projet et obtenir un devis personnalisé." />
      <meta property="og:title" content="Notre expertise en développement 3D | CDO Apps" />
      <meta property="og:description" content="Découvrez notre expertise en développement 3D. Nous repoussons les limites de l'innovation en intégrant des technologies de pointe pour créer des simulations interactives et des expériences de jeu divertissantes. Contactez-nous pour discuter de votre projet et obtenir un devis personnalisé." />
    </Helmet>,
    <PageTitle id="developpement-3d" title="Développement 3D" text="Explorez de nouvelles dimensions avec l’expertise polyvalente de CDO Apps en développement 3D, moteur graphique et jeu vidéo. Nous repoussons les limites de l’innovation en intégrant des technologies de pointe pour créer des simulations interactives et des expériences de jeu divertissantes." logo={<GraphicsIcon />} />,
    <div className="hidden bg-cyan"></div>,
    <Process steps={steps} color="cyan" />,
    <SkillProjects items={projects} color="cyan" />,
    <Stack techs={techs} />,
    <CallToAction id="contact" title="Votre projet nécessite du développement 3D&nbsp;?" text="Nous sommes là pour vous aider à explorer de nouvelles dimensions avec notre expertise en développement 3D. Contactez-nous pour discuter de votre projet et obtenir un devis personnalisé." action="Contactez-nous" href="/contact" />,
    <OtherSkills mainSkill="graphics" />,
    <Projects />,
    <Drawer route={route} />
  ];
}

export default Graphics;
