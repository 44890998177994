function Project({image, title, description, focus, slug, color, left}) {
  return (
    <div className="flex flex-col items-center lg:items-start lg:flex-row lg:space-x-16">
      {left && (
        <div className="relative w-full max-w-[600px] lg:w-1/2 lg:my-auto">
          <img src={image} alt={title} className="object-cover w-full h-auto rounded-tl-[40px] rounded-bl-[40px] rounded-br-[40px]" />
        </div>
      )}
      <div className="flex flex-col flex-1 mt-8 lg:mt-0">
        <h2 className={"text-2xl font-bold text-white text-center lg:text-left py-2 px-5 mr-auto rounded-[4px] uppercase " + `bg-${color}`}>{title}</h2>
        <br />
        <h3 className="text-base font-bold text-dark">Description&nbsp;:</h3>
        <br />
        <div className="text-base text-dark" dangerouslySetInnerHTML={{__html: description}}></div>
        <br />
        <h3 className="text-base font-bold text-dark">Notre rôle&nbsp;:</h3>
        <br />
        <div className="text-base text-dark" dangerouslySetInnerHTML={{__html: focus}}></div>
        <br />
        <a className="text-lg font-bold text-accent underline transition duration-300 hover:opacity-50 ml-auto" href={`/realisations/${slug}`}>{">"} En savoir plus</a>
      </div>
      {!left && (
        <div className="relative w-full max-w-[600px] lg:w-1/2 lg:my-auto">
          <img src={image} alt={title} className="object-cover w-full h-auto rounded-tl-[40px] rounded-tr-[40px] rounded-br-[40px]" />
        </div>
      )}
    </div>
  );
}

export default Project;