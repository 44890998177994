import React from 'react';
import { Helmet } from 'react-helmet';

import CallToAction from "../common/CallToAction";
import Drawer from "../common/Drawer";
import Projects from "../home/Projects";
import PageTitle from "./include/PageTitle";

import { ReactComponent as ProjectManagementIcon } from "../assets/skills/project_management.svg";
import OtherSkills from "./include/OtherSkills";
import SkillProjects from "./include/Projects";
import Process from "./include/Process";
import Stack from "./include/Stack";

import ProjectQuery from "../model/Projects";

import Git from "../assets/skills/Git.png";
import GitLab from "../assets/skills/GitLab.png";
import Atlassian from "../assets/skills/Atlassian.png";
import Notion from "../assets/skills/Notion.png";
import Slack from "../assets/skills/Slack.png";

function ProjectManagement({route}) {
    const steps = [
      {
        title: "Spécifications",
        description: "La première étape de tout projet est la définition des spécifications. Nous travaillons en étroite collaboration avec vous pour définir vos besoins et objectifs, et établir un cahier des charges détaillé."
      },
      {
        title: "Planification",
        description: "Une planification minutieuse est essentielle pour la réussite de tout projet. Nous établissons un calendrier précis et détaillé pour garantir une coordination efficace et respecter les délais."
      },
      {
        title: "Suivi",
        description: "Nous utilisons des outils sécurisés pour gérer le code source et les versions du projet&nbsp;: chaque modification est suivie et documentée, ce qui permet de maintenir un historique complet des changements.<br /><br />En parallèle, nous vous communiquons de façon constante l’avancement du projet pour vous tenir informé."
      },
      {
        title: "Livraison",
        description: "Une fois le projet réalisé, nous procédons à sa livraison dans les délais convenus. Nous assurons également une période de garantie pour vous permettre de valider votre projet en conditions réelles selon les critères définis pendant la phase de spécifications."
      }
    ];
    const projects = ProjectQuery({filter: "project-management"});
    const techs = [
      { name: "Git", logo: Git },
      { name: "GitLab", logo: GitLab },
      { name: "Atlassian", logo: Atlassian },
      { name: "Notion", logo: Notion },
      { name: "Slack", logo: Slack }
    ];

  return [
    <Helmet>
      <title>Notre expertise en gestion de projet | CDO Apps</title>
      <meta name="description" content="Découvrez notre expertise en gestion de projet. La réussite de chaque projet repose sur une gestion efficace. Notre expertise en gestion de projet assure une coordination transparente, du concept à la livraison. Nous maintenons une communication constante pour garantir la réalisation réussie de vos projets. Contactez-nous pour discuter de votre projet et obtenir un devis personnalisé." />
      <meta property="og:title" content="Notre expertise en gestion de projet | CDO Apps" />
      <meta property="og:description" content="Découvrez notre expertise en gestion de projet. La réussite de chaque projet repose sur une gestion efficace. Notre expertise en gestion de projet assure une coordination transparente, du concept à la livraison. Nous maintenons une communication constante pour garantir la réalisation réussie de vos projets. Contactez-nous pour discuter de votre projet et obtenir un devis personnalisé." />
    </Helmet>,
    <PageTitle id="gestion-projet" title="Gestion de projet" text="La réussite de chaque projet repose sur une gestion efficace.<br />Notre expertise en gestion de projet assure une coordination transparente, du concept à la livraison. Nous maintenons une communication constante pour garantir la réalisation réussie de vos projets." logo={<ProjectManagementIcon />} />,
    <div className="hidden bg-yellow"></div>,
    <Process steps={steps} color="yellow" />,
    <SkillProjects items={projects} color="yellow" />,
    <Stack techs={techs} />,
    <CallToAction id="contact" title="Vous avez besoin d'une gestion de projet efficace&nbsp;?" text="Nous sommes là pour vous aider à coordonner et gérer vos projets de manière transparente. Contactez-nous pour discuter de votre projet et obtenir un devis personnalisé." action="Contactez-nous" href="/contact" />,
    <OtherSkills mainSkill="project-management" />,
    <Projects />,
    <Drawer route={route} />
  ];
}

export default ProjectManagement;
