import React from 'react';

function Skill({image, title, description, href, corners}) {
  corners = corners ? corners.split(' ') : [];

  const topLeftCorner = corners.includes('top-left') ? 'rounded-tl-[40px]' : '';
  const topRightCorner = corners.includes('top-right') ? 'rounded-tr-[40px]' : '';
  const bottomLeftCorner = corners.includes('bottom-left') ? 'rounded-bl-[40px]' : '';
  const bottomRightCorner = corners.includes('bottom-right') ? 'rounded-br-[40px]' : '';

  return (
    <div className={`relative flex flex-1 min-h-[21.25rem] overflow-clip bg-white ${topLeftCorner} ${topRightCorner} ${bottomLeftCorner} ${bottomRightCorner}`}>
      <div className="w-full py-10 ml-10 mr-10 sm:mr-0">
        <h3 className="text-2xl/[1.375rem] font-bold text-dark uppercase">{title}</h3>
        <br />
        <div className="text-base/[1.375rem] text-dark" dangerouslySetInnerHTML={{__html: description}}></div>
        <div className="sm:hidden opacity-20 absolute top-[10px] right-[10px]">
          {image}
        </div>
        <div className="sm:hidden flex flex-col">
          <br />
          <a className="text-lg ml-auto font-bold text-accent underline transition duration-300 hover:opacity-50" href={href}>{">"} En savoir plus</a>
        </div>
      </div>
      <div className="hidden sm:flex flex-col min-w-[180px] w-[180px] py-10 mr-10">
        {image}
        <div className="flex grow"></div>
        <a className="text-lg ml-auto font-bold text-accent underline transition duration-300 hover:opacity-50" href={href}>{">"} En savoir plus</a>
      </div>
    </div>
  );
}

export default Skill;
