import React from 'react';
import { Helmet } from 'react-helmet';

import Drawer from "../common/Drawer";

import Banner from "./include/Banner";
import Details from "./include/Details";
import Features from "./include/Features";
import Gallery from "./include/Gallery";
import HeroHeader from "./include/HeroHeader";
import Stores from "./include/Stores";
import CallToAction from '../common/CallToAction';

function Project({route, project}) {
  var extraComponents = project.screenshots || project.features || project.stores;

  const components = [
    <Helmet>
      <title>{project.title} | CDO Apps</title>
      <meta name="description" content={project.description} />
      <meta property="og:title" content={`${project.title} | CDO Apps`} />
      <meta property="og:description" content={project.description} />
    </Helmet>,
    <Banner title={project.title} image={project.banner} />,
    <HeroHeader title={project.title} description={project.description} image={project.image} techs={project.techs} website={project.website} />,
    <Details info={project.info} focus={project.focus} lastSection={!extraComponents} />
  ];

  if (project.screenshots) {
    extraComponents = project.features || project.stores;
    components.push(<Gallery screenshots={project.screenshots} lastSection={!extraComponents} />);
  }

  if (project.features) {
    extraComponents = project.stores;
    components.push(<Features title={project.title} features={project.features} lastSection={!extraComponents} />);
  }

  if (project.stores) {
    components.push(<Stores appStore={project.stores.appStore} googlePlay={project.stores.googlePlay} steam={project.stores.steam} />);
  }

  return [
    ...components,
    <CallToAction id="contact" title="Vous avez une idée d'application en tête&nbsp;?" text="Nous sommes là pour vous aider à la concrétiser. CDO Apps est spécialisé dans le développement d'applications mobiles. Nous vous accompagnons de la conception à la publication sur les stores. Contactez-nous pour discuter de votre projet et obtenir un devis personnalisé." action="Contactez-nous" href="/contact" />,
    <Drawer route={route} />
  ];
}

export default Project;