import React from 'react';
import { ReactComponent as LogoLight } from '../assets/logo_light.svg';
import { ReactComponent as LogoDark } from '../assets/logo_dark.svg';

function TabAnchor({ style, selected, href, content }) {
  const color = style === "dark" ? "text-white" : "text-dark";
  const font = selected ? "font-bold" : "font-medium";
  return (
    <li><a className={`${color} text-lg ${font} lowercase transition duration-300 hover:opacity-50`} href={href}>{content}</a></li>
  );
}

function Header({ route }) {
  const style = route.style;
  const drawer = route.drawer;
  
  const setDrawerVisible = drawer[1];
  const path = route.status < 400 ? window.location.pathname : "/not-found";

  return (
    <div className="absolute w-full h-[6.25rem] px-10">
      <div className="flex max-w-[1200px] mx-auto relative z-20 items-center h-full">
        <nav className="flex items-center w-full h-full">
          <a className="transition duration-300 hover:opacity-50" href="/">{style === "dark" ? <LogoDark className="text-white" /> : <LogoLight className="text-dark" />}</a>
          <div className="flex-grow md:hidden"></div>
          <ul className="hidden md:flex space-x-8 lg:space-x-20 mx-auto px-2.5">
            <TabAnchor style={style} href="/#expertises" content="Expertises" selected={path.startsWith("/expertises")} />
            <TabAnchor style={style} href="/realisations" content="Réalisations" selected={path.startsWith("/realisations")} />
            <TabAnchor style={style} href="/equipe" content="L'équipe" selected={path.startsWith("/equipe")} />
            <TabAnchor style={style} href="/contact" content="Contact" selected={path.startsWith("/contact")} />
          </ul>
          <button
            className={style === "dark" ? "md:hidden text-white font-bold text-4xl transition duration-300 hover:opacity-50" : "md:hidden text-dark font-bold text-4xl transition duration-300 hover:opacity-50"}
            onClick={() => setDrawerVisible(true)}>
            ☰
          </button>
        </nav>
      </div>
    </div>
  );
}

export default Header;