import React from 'react';
import Figure from './figures/Figure';

import MembersQuery from '../model/Members';
import ProjectQuery from '../model/Projects';

function Figures() {
  const since = 2010;
  const members = MembersQuery({filter: 'internal'});
  const projects = ProjectQuery();
  
  return (
    <section id="chiffres" className="relative overflow-x-clip py-[2.5rem] px-10">
      <div className="flex max-w-[1200px] mx-auto relative z-20">
        <div className="flex flex-wrap w-full justify-center mx-10">
          <Figure amount={members.length} title="Collaborateurs" />
          <Figure amount={new Date().getFullYear() - since} title="Années d'expérience" />
          <Figure amount={projects.length} title="Projets réalisés" />
        </div>
      </div>
    </section>
  );
}

export default Figures;
