import React from 'react';
import { Helmet } from 'react-helmet';

import Drawer from "./common/Drawer";
import PageTitle from "./common/PageTitle";

function sypher(email) {
  return `<a class="text-lg font-bold text-accent underline transition duration-300 hover:opacity-50" data-desypher="${btoa(email)}" href="#" data-href="${btoa("mailto:" + email)}">
      chiffré
    </a>`;
}

function Section({id, title, text, className}) {
  return (
      <section id={id} className={"relative overflow-x-clip px-10  pt-10" + (className ? " " + className : "")}>
        <div className="max-w-[1200px] mx-auto">
          <h2 className="text-base font-bold text-dark uppercase">{title}&nbsp;:</h2>
          <br />
          <div className="text-base text-dark" dangerouslySetInnerHTML={{__html: text}} />
        </div>
      </section>
  );
}

function Contact({route}) {
  return [
    <Helmet>
      <title>Nous contacter | CDO Apps</title>
      <meta name="description" content="Vous avez besoin de réaliser une application mobile au design unique et à la pointe de la technologie&nbsp;? N'hésitez pas à nous contacter, nous serons ravis de vous accompagner dans votre projet&nbsp;!" />
      <meta property="og:title" content="Nous contacter | CDO Apps" />
      <meta property="og:description" content="Vous avez besoin de réaliser une application mobile au design unique et à la pointe de la technologie&nbsp;? N'hésitez pas à nous contacter, nous serons ravis de vous accompagner dans votre projet&nbsp;!" />
    </Helmet>,
    <PageTitle id="contact" title="Contact" text="Vous avez besoin de réaliser une application mobile au design unique et à la pointe de la technologie&nbsp;?<br />N'hésitez pas à nous contacter, nous serons ravis de vous accompagner dans votre projet&nbsp;!"/>,
    <Section id="information" title="Page en construction" text={"Cette page est en cours de construction&nbsp;: un chatbot sera bientôt disponible pour vous permettre de nous contacter directement depuis notre site web.<br /><br />En attendant, vous pouvez nous envoyer un email à l'adresse suivante&nbsp;: " + sypher("contact@cdo-apps.com") + "."} className="pb-10"/>,
    <Drawer route={route} />
  ];
}

export default Contact;
