import React from 'react';
import { Helmet } from 'react-helmet';

import CallToAction from "../common/CallToAction";
import Drawer from "../common/Drawer";
import Projects from "../home/Projects";
import PageTitle from "./include/PageTitle";

import { ReactComponent as BackEndIcon } from "../assets/skills/backend.svg";
import OtherSkills from "./include/OtherSkills";
import SkillProjects from "./include/Projects";
import Process from "./include/Process";
import Stack from "./include/Stack";

import ProjectQuery from "../model/Projects";

import NodeJS from "../assets/skills/NodeJS.png";
import MongoDB from "../assets/skills/MongoDB.png";
import MySQL from "../assets/skills/MySQL.png";
import Firebase from "../assets/skills/Firebase.png";
import JWT from "../assets/skills/JWT.png";
import Docker from "../assets/skills/Docker.png";


function BackEnd({route}) {
  const steps = [
    {
      title: "Modélisation de données",
      description: "Dans une recherche de performance et de sécurité, la modélisation de données est une étape cruciale. Nous définissons la structure de vos bases de données pour garantir une gestion efficace des informations."
    },
    {
      title: "Documentation API",
      description: "Pour faciliter l’intégration de vos applications, nous fournissons une documentation claire et détaillée de vos API. Cette documentation est également la base sur laquelle sera développée votre application front-end."
    },
    {
      title: "Authentification",
      description: "La sécurité doit être une priorité. Nous mettons en place des systèmes d’authentification robustes pour protéger vos données et garantir l’accès uniquement aux utilisateurs autorisés."
    },
    {
      title: "Déploiement",
      description: "Une fois votre application prête, nous assurons un déploiement efficace en deux phases&nbsp;: un déploiement de test pour vérifier le bon fonctionnement de l’application, puis un déploiement final pour rendre votre application accessible au public."
    }
  ];
  const projects = ProjectQuery({filter: "back-end"});
  const techs = [
    { name: "Node.js", logo: NodeJS },
    { name: "Firebase", logo: Firebase },
    { name: "MongoDB", logo: MongoDB },
    { name: "MySQL", logo: MySQL },
    { name: "JWT", logo: JWT },
    { name: "Docker", logo: Docker }
  ];

  return [
    <Helmet>
      <title>Notre expertise Back End | CDO Apps</title>
      <meta name="description" content="Découvrez notre expertise Back End. Nous assurons une base solide pour vos applications, de la gestion des bases de données à la sécurité avancée. Contactez-nous pour discuter de votre projet et obtenir un devis personnalisé." />
      <meta property="og:title" content="Notre expertise Back End | CDO Apps" />
      <meta property="og:description" content="Découvrez notre expertise Back End. Nous assurons une base solide pour vos applications, de la gestion des bases de données à la sécurité avancée. Contactez-nous pour discuter de votre projet et obtenir un devis personnalisé." />
    </Helmet>,
    <PageTitle id="back-end" title="Back end" text="La solidité d’une application réside dans son infrastructure. Avec notre expertise Back End, nous assurons une base solide pour vos applications. De la gestion efficace des bases de données à la sécurité avancée, nous optimisons les performances pour garantir une expérience utilisateur fiable et sécurisée." logo={<BackEndIcon />} />,
    <div className="hidden bg-green"></div>,
    <Process steps={steps} color="green" />,
    <SkillProjects items={projects} color="green" />,
    <Stack techs={techs} />,
    <CallToAction id="contact" title="Votre projet nécessite une expertise Back End&nbsp;?" text="Nous sommes là pour vous aider à concevoir une base solide pour vos applications. Contactez-nous pour discuter de votre projet et obtenir un devis personnalisé." action="Contactez-nous" href="/contact" />,
    <OtherSkills mainSkill="back-end" />,
    <Projects />,
    <Drawer route={route} />
  ];
}

export default BackEnd;
