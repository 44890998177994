function PageTitle({id, logo, title, text}) {
  return (
      <section id={id} className="relative bg-primary-600 overflow-x-clip py-[5rem] px-10">
        <div className="relative flex max-w-[1200px] mx-auto pt-[6.25rem] z-20 md:space-x-14">
          <div className="w-[192px] content-center absolute top-[10px] right-[10px] opacity-20 md:static md:opacity-100">
            {logo}
          </div>
          <div className="z-30">
            <h1 className="text-dark font-bold text-3xl uppercase">{title}</h1>
            {text && [
              <br />,
              <div className="text-dark font-bold text-base" dangerouslySetInnerHTML={{__html: text}}></div>
            ]}
            <br />
            <div className="flex">
              <a className="bg-accent text-white text-center font-bold rounded-full uppercase py-[1.25rem] px-[60px] transition duration-300 hover:opacity-50" href="/contact">Parlez-nous de votre projet</a>
            </div>
          </div>
        </div>
    </section>
    );
  }

export default PageTitle;