import React from 'react';
import SectionHeader from '../common/SectionHeader';

import TeamImage from '../assets/home/team.jpeg';

function Team() {
  return (
    <section id="equipe" className="relative bg-accent min-h-[40.125rem] overflow-x-clip py-[5rem] px-10">
      <div className="max-w-[1200px] mx-auto z-20">
        <img src={TeamImage} alt="Qui sommes-nous ?" className="hidden sm:block absolute w-[600px] aspect-square left-1/2 top-[-40px] transform translate-x-[40px] rounded-tl-[80px] rounded-tr-[80px] rounded-br-[80px] z-30" />
        <div className="flex flex-col w-full sm:w-1/2 sm:pr-5">
          <SectionHeader title="Qui sommes-nous&nbsp;?" subtitle="Dans les coulisses" style="dark" />
          <div className="text-white text-base text-left pt-[2.5rem] pb-[4.375rem]">
            Nous sommes une équipe de passionnés, experts en développement d’applications mobiles. Notre mission est de transformer vos idées en solutions mobiles performantes, offrant une expérience utilisateur exceptionnelle. Nous mettons notre expertise et notre créativité à votre service pour concevoir des applications innovantes, intuitives et esthétiques, parfaitement adaptées à vos besoins.
            <br /><br />
            Nous sommes développeurs, designers, chefs de projet, experts en sécurité et en performance. Nous collaborons étroitement avec vous pour garantir la réussite de vos projets, de la conception à la livraison. Notre approche sur mesure et notre engagement total vous assurent des solutions mobiles de qualité exceptionnelle, répondant parfaitement à vos attentes.
          </div>
          <a className="text-lg ml-auto font-bold text-white underline transition duration-300 hover:opacity-50" href="/equipe">{">"} En savoir plus</a>
        </div>
      </div>
    </section>
  );
}

export default Team;
