import { useState, useEffect } from 'react';

import Home from './Home';
import NotFound from './NotFound';
import Projects from './Projects';
import Team from './Team';
import Contact from './Contact';
import LegalNotice from './LegalNotice';

import Project from './projects/Project';

import MobileApps from './skills/MobileApps';
import Graphics from './skills/Graphics';
import BackEnd from './skills/BackEnd';
import ProjectManagement from './skills/ProjectManagement';

import ProjectQuery from './model/Projects';

const notFound = {
  status: 404,
  pageBuilder: (route) => <NotFound route={route} />
};

const router = {
  "/": {
    style: "dark",
    pageBuilder: (route) => <Home route={route} />
  },
  "/expertises": {
    "/apps-mobiles": {
      pageBuilder: (route) => <MobileApps route={route} />
    },
    "/back-end": {
      pageBuilder: (route) => <BackEnd route={route} />
    },
    "/developpement-3d": {
      pageBuilder: (route) => <Graphics route={route} />
    },
    "/gestion-de-projet": {
      pageBuilder: (route) => <ProjectManagement route={route} />
    },
  },
  "/realisations": {
    "/": {
      pageBuilder: (route) => <Projects route={route} />
    },
    ... ProjectQuery().reduce((acc, project) => {
      acc[`/${project.slug}`] = {
        pageBuilder: (route) => <Project route={route} project={project} />
      };
      return acc;
    }, {})
  },
  "/equipe": {
    pageBuilder: (route) => <Team route={route} />
  },
  "/contact": {
    pageBuilder: (route) => <Contact route={route} />
  },
  "/mentions-legales": {
    pageBuilder: (route) => <LegalNotice route={route} />
  },
  "/sitemap.xml": {
    pageBuilder: (route) => <Sitemap />
  }
};

function buildSitemap(router, path, urls) {
  path = path ?? "";
  urls = urls ?? [];

  if (path === "/sitemap.xml") {
    return;
  }

  for (const route in router) {
    const page = router[route];

    if (page.pageBuilder === undefined) {
      buildSitemap(page, path + route, urls);
      continue;
    }

    urls.push((
        <url>
          <loc>{`https://cdo-apps.com${path}${route}`}</loc>
          <lastmod>2025-02-04</lastmod>
        </url>
    ));
  }

  return urls;
}

function Sitemap() {
  return (
    <pre style={{whiteSpace: "pre-wrap"}}>
      <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
        { buildSitemap(router) }
      </urlset>
    </pre>
  );
}

function getRoute(router, path) {
  const index = path.indexOf('/', 1);
  
  const route = index === -1 ? path : path.substring(0, index);
  router = router[route];

  if (typeof router !== 'object') {
    return notFound;
  }

  path = index === -1 ? '/' : path.substring(index);
  if (router.pageBuilder === undefined) {
    return getRoute(router, path);
  }

  return (path === '/') ? router : getRoute(router, path);
};

function Router({path, onRouteChanged}) {
  const route = getRoute(router, path);
  
  route.status = route.status ?? 200;
  route.style = route.style ?? "light";
  route.drawer = useState(false);

  useEffect(() => {
    const event = new Event('AppRouteChanged');
    window.dispatchEvent(event);
  }, [route.drawer[0]]);
  
  if (onRouteChanged) {
    onRouteChanged(route);
  }

  return route.pageBuilder(route);
}

export default Router;