import React, { Component } from 'react';

class MediaQuery extends Component {
  constructor(props) {
    super(props)

    this.smallWidth = `(min-width: ${props["sm-width"] || "640px"}`;
    this.mediumWidth = `(min-width: ${props["md-width"] || "768px"}`;
    this.largeWidth = `(min-width: ${props["lg-width"] || "1024px"}`;

    this.state = {
        small: window.matchMedia(this.smallWidth).matches,
        medium: window.matchMedia(this.mediumWidth).matches,
        large: window.matchMedia(this.largeWidth).matches
    };
  }

  setMatches(name, matches) {
    this.setState({ [name]: matches });
  }

  componentDidMount() {
    window.matchMedia(this.smallWidth).addEventListener('change', e => this.setMatches('small', e.matches));
    window.matchMedia(this.mediumWidth).addEventListener('change', e => this.setMatches('medium', e.matches));
    window.matchMedia(this.largeWidth).addEventListener('change', e => this.setMatches('large', e.matches));
  }

  render() {
    const children = this.props;
    const { small, medium, large } = this.state;
    
    if (!small)
      return children.xsmall || children.small || children.medium || children.large;

    if (!medium)
      return children.small || children.medium || children.large;

    if (!large)
      return children.medium || children.large;

    return children.large;
  }
}

export default MediaQuery;