import { ReactComponent as Clement } from '../assets/team/clement.svg';
import { ReactComponent as Lydie } from '../assets/team/lydie.svg';
import { ReactComponent as Romuald } from '../assets/team/romuald.svg';
import { ReactComponent as Vincent } from '../assets/team/vincent.svg';
import { ReactComponent as Marion } from '../assets/team/f1.svg';
import { ReactComponent as Uriel } from '../assets/team/m2.svg';
import { ReactComponent as Maxime } from '../assets/team/m3.svg';

const DataSource = [
    {
        name: "Clément",
        image: (<Clement />),
        position: "Dirigeant associé",
        team: "internal",
        techs: ["Swift", "Kotlin", "Flutter"],
        text: "Ingénieur en développement 3D et spécialisé dans les technologies mobiles depuis 2010, Clément a fondé CDO Apps en 2016.<br /><br />Il est le garant de la qualité des applications développées par le studio.",
    },
    {
        name: "Lydie",
        image: (<Lydie />),
        position: "Associée et cheffe de projet",
        team: "internal",
        techs: ["C", "Python", "Atlassian"],
        text: "Ingénieure en système embarqué, Lydie est co-fondatrice de CDO Apps.<br /><br />Elle est en charge de la gestion des projets et de la relation client. Elle assure le suivi des développements et la qualité des livrables.",
    },
    {
        name: "Romuald",
        image: (<Romuald />),
        position: "Développeur",
        team: "internal",
        techs: ["Swift", "Flutter"],
        text: "Ingénieur en robotique, Romuald se spécialise dans le développement d'applications iOS et rejoint CDO Apps en 2020.<br /><br />Flutter est devenu son outil de prédilection pour le développement multiplateforme.",
    },
    {
        name: "Vincent",
        image: (<Vincent />),
        position: "Développeur",
        team: "internal",
        techs: ["Unity"],
        text: "Développeur dans le jeu vidéo, Vincent nous rejoint en 2019.<br /><br />Son expertise en développement Unity et en game design est un atout pour les projets de jeux mobiles et expériences interactives.",
    },
    {
        name: "Marion",
        image: (<Marion />),
        position: "Directrice artistique",
        team: "external",
        techs: ["Photoshop", "Illustrator"],
        text: "Graphiste et illustratrice, Marion est notre partenaire pour la création de l'identité visuelle de vos applications.<br /><br />Elle conçoit les interfaces et les éléments graphiques de nos projets.",
        website: "https://www.marion-wang.fr",
    },
    {
        name: "Uriel",
        image: (<Uriel />),
        position: "Animateur 2D/3D",
        team: "external",
        techs: ["Blender", "Spine"],
        text: "Avec son expertise en animation, Uriel apporte du dynamisme et de la vie à vos projets.",
        website: "https://www.artstation.com/fogryu",
    },
    {
        name: "Maxime",
        image: (<Maxime />),
        position: "Sound designer",
        team: "external",
        techs: ["FL Studio"],
        text: "Maxime est un compositeur et sound designer spécialisé dans la création d'assets audio pour les jeux vidéo.<br /><br />Nous collaborons avec lui pour enrichir les expériences sonores de nos applications.",
        website: "https://soundcloud.com/maximeferrieu",
    }
];

function Query({filter, sort, limit, offset} = {}) {
    let members = DataSource;

    if (filter) {
        members = members.filter(member => member.team == filter);
    }

    if (sort) {
        members = members.sort((a, b) => a.name.localeCompare(b.name));
    }

    if (limit) {
        members = members.slice(offset || 0, limit);
    }

    return members;
}

export default Query;