import React from 'react';
import { ReactComponent as BackgroundImage } from '../assets/home/hero_background.svg';

function HeroHeader() {
  return (
    <section id="hero-header" className="relative bg-gradient-to-r from-primary-200 to-primary-400 min-h-[50rem] content-center overflow-x-clip p-10">
      <BackgroundImage className="absolute bottom-[-50px] sm:left-[50%] opacity-20 sm:opacity-100 z-10" />
      <div className="flex max-w-[1200px] mx-auto relative sm:static pt-[6.25rem] z-20">
        <div className="w-full sm:w-1/2 sm:pr-5">
          <h1 className="text-white text-4xl font-bold text-left uppercase">Développeur d'apps mobiles</h1>
          <div className="text-white text-base font-bold text-left py-[2.25rem]">CDO Apps est votre partenaire privilégié pour la conception d’applications mobiles. Notre expertise exceptionnelle allie créativité, innovation et excellence technique.<br />Nous transformons vos idées en solutions mobiles performantes, offrant une expérience utilisateur exceptionnelle.</div>
          <div className="flex justify-center sm:justify-start">
            <a className="bg-accent text-white text-center font-bold rounded-full uppercase py-[1.25rem] px-[60px] transition duration-300 hover:opacity-50" href="/contact">Contactez-nous</a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroHeader;
