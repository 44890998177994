import React from "react";

function PageControl({ selected, setSelected, length }) {
  return (
    <div className="flex justify-center w-full">
      {Array.from({ length }).map((_, index) => (
        <button
          key={index}
          onClick={() => setSelected(index)}
          className="flex justify-center items-center w-[20px] h-[20px] mx-[3px] rounded-full bg-primary-100 p-[1px] transition duration-300 hover:opacity-50">
          <div className="flex justify-center items-center w-full h-full rounded-full bg-white p-[2px]">
            {selected === index && (
              <div className="w-full h-full rounded-full bg-accent" />
            )}
          </div>
        </button>
      ))}
    </div>
  );
}

export default PageControl;