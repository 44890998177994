function TextBlock({title, text}) {
    return (
        <div className="flex flex-col flex-1">
          <h3 className="text-base font-bold text-dark uppercase">{title}&nbsp;:</h3>
          <br />
          <div className="text-base text-dark" dangerouslySetInnerHTML={{__html: text}}></div>
        </div>
    );
}

function Details({info, focus, lastSection}) {
    return (
        <section id="details" className={"relative overflow-x-clip px-10" + (lastSection ? " pb-[5rem]" : "")}>
          <div className="relative max-w-[1200px] mx-auto">
            <div className="flex flex-col items-center gap-x-12 lg:items-start lg:flex-row">
              { info && <TextBlock title="Description détaillée" text={info} /> }
              { focus && [
                info && <br className="lg:hidden" />,
                <TextBlock title="Rôle de CDO Apps" text={focus} />
              ]}
            </div>
          </div>
        </section>
    );
}

export default Details;