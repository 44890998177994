import React from 'react';
import { Helmet } from 'react-helmet';

import CallToAction from "../common/CallToAction";
import Drawer from "../common/Drawer";
import Projects from "../home/Projects";
import PageTitle from "./include/PageTitle";

import { ReactComponent as MobileAppsIcon } from "../assets/skills/mobile_apps.svg";
import OtherSkills from "./include/OtherSkills";
import SkillProjects from "./include/Projects";
import Process from "./include/Process";
import Stack from "./include/Stack";

import ProjectQuery from "../model/Projects";

import iOS from "../assets/skills/iOS.png";
import Android from "../assets/skills/Android.png";
import Swift from "../assets/skills/Swift.png";
import Kotlin from "../assets/skills/Kotlin.png";
import ObjectiveC from "../assets/skills/ObjectiveC.png";
import Java from "../assets/skills/Java.png";
import Flutter from "../assets/skills/Flutter.png";
import XD from "../assets/skills/XD.png";
import Figma from "../assets/skills/Figma.png";

function MobileApps({route}) {
  const steps = [
    {
      title: "UI/UX Design",
      description: "L’expérience utilisateur est au cœur de nos préoccupations. Nous concevons des interfaces intuitives et ergonomiques pour offrir une expérience utilisateur fluide et agréable."
    },
    {
      title: "Développement",
      description: "Notre équipe de développeurs expérimentés maîtrise les dernières technologies pour concevoir des applications mobiles performantes et fiables, respectant rigoureusement les maquettes et spécifications."
    },
    {
      title: "Tests",
      description: "La qualité est notre priorité. Nous réalisons des tests rigoureux pour garantir le bon fonctionnement de vos applications sur toutes les plateformes et dans toutes les conditions."
    },
    {
      title: "Publication",
      description: "Une fois votre application prête, nous nous occupons de sa publication sur les stores (App Store, Google Play) pour la rendre accessible au plus grand nombre d’utilisateurs."
    }
  ];
  const projects = ProjectQuery({filter: "mobile-apps"});
  const techs = [
    { name: "iOS", logo: iOS },
    { name: "Android", logo: Android },
    { name: "Flutter", logo: Flutter },
    { name: "Swift", logo: Swift },
    { name: "Kotlin", logo: Kotlin },
    { name: "Objective-C", logo: ObjectiveC },
    { name: "Java", logo: Java },
    { name: "XD", logo: XD },
    { name: "Figma", logo: Figma }
  ];

  return [
    <Helmet>
      <title>Notre expertise en application mobile | CDO Apps</title>
      <meta name="description" content="Découvrez notre expertise en application mobile. Nous concevons des applications intuitives, esthétiques et parfaitement adaptées à diverses plateformes. Contactez-nous pour discuter de votre projet et obtenir un devis personnalisé." />
      <meta property="og:title" content="Notre expertise en application mobile | CDO Apps" />
      <meta property="og:description" content="Découvrez notre expertise en application mobile. Nous concevons des applications intuitives, esthétiques et parfaitement adaptées à diverses plateformes. Contactez-nous pour discuter de votre projet et obtenir un devis personnalisé." />
    </Helmet>,
    <PageTitle id="apps-mobiles" title="Application mobile" text="CDO Apps excelle dans le développement d’applications mobiles de qualité exceptionnelle. Notre équipe créative et expérimentée maîtrise les dernières technologies pour concevoir des applications intuitives, esthétiques et parfaitement adaptées à diverses plateformes, offrant une expérience utilisateur fluide et engageante." logo={<MobileAppsIcon />} />,
    <div className="hidden bg-pink"></div>,
    <Process steps={steps} color="pink" />,
    <SkillProjects items={projects} color="pink" />,
    <Stack techs={techs} />,
    <CallToAction id="contact" title="Un projet d'application mobile en tête&nbsp;?" text="Nous sommes là pour vous aider à concrétiser votre projet. CDO Apps est spécialisé dans le développement d'applications mobiles. Nous vous accompagnons de la conception à la publication sur les stores. Contactez-nous pour discuter de votre projet et obtenir un devis personnalisé." action="Contactez-nous" href="/contact" />,
    <OtherSkills mainSkill="mobile-apps" />,
    <Projects />,
    <Drawer route={route} />
  ];
}

export default MobileApps;
